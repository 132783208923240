import { Intl } from "@i18n/Intl";
import React from "react";
import styled from "styled-components";
import { Flex } from "@components/Flex";
import ModalAbsolute, { ModalAlignment, Menu } from "@components/Modals/ModalAbsolute";
import { StyledMenuOptions } from "@components/Modals/StyledMenu";
import SvgIcnDelete from "@components/svg/IcnDelete";
import SvgIcnDots from "@components/svg/IcnDots";
import SvgIcnFolder from "@components/svg/IcnFolder";
import SvgIcnRename from "@components/svg/IcnRename";

type FolderButtonProps = {
    btnLabel: string;
    onClick: () => void;
    isDeletable: boolean;
    onRenameClick: () => void;
    onDeleteClick: () => void;
};

export class FolderButton extends React.Component<FolderButtonProps> {
    render() {
        const { btnLabel, onClick } = this.props;

        return (
            <StyledFolder>
                <StyledFolderButton onClick={onClick}>
                    <SvgIcnFolder />
                    <span>{btnLabel}</span>
                </StyledFolderButton>

                <ModalAbsolute
                    titleText="Options"
                    modalAlignment={ModalAlignment.BottomRight}
                    activateElement={
                        <FolderDropdown>
                            <SvgIcnDots />
                        </FolderDropdown>
                    }
                    containerPositionFixed={true}
                >
                    <StyledMenuOptions>
                        <Menu.Option
                            btnLabel={Intl.formatMessage({ id: "components.folders.options.rename" })}
                            icon={<SvgIcnRename />}
                            iconPosition="left"
                            onClick={() => this.props.onRenameClick()}
                        />
                        {this.props.isDeletable && (
                            <Menu.Option
                                btnLabel={Intl.formatMessage({ id: "components.folders.options.delete" })}
                                icon={<SvgIcnDelete />}
                                iconPosition="left"
                                onClick={() => this.props.onDeleteClick()}
                            />
                        )}
                    </StyledMenuOptions>
                </ModalAbsolute>
            </StyledFolder>
        );
    }
}

export const LoadingFolderButton = styled(Flex.Container).attrs(() => ({
    $alignItems: "center",
    as: "button",
    type: "button",
}))`
    min-width: 157px;
    height: 40px;
    border-radius: ${props => props.theme.box.button.borderRadius}px;
    margin-right: 20px;
    pointer-events: none;
`;

const StyledFolderButton = styled.button`
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    line-height: 40px;
    font-size: 10px;
    font-weight: 700;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;

    > svg {
        min-width: 14px;
        margin-right: 10px;
    }

    svg {
        color: ${props => props.theme.box.button.folder.icon.fillColor};
    }

    &:hover {
        border-color: transparent;
        filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.05));

        svg {
            color: inherit;
        }
    }
`;

const StyledFolder = styled(Flex.Container).attrs(() => ({
    $alignItems: "center",
}))`
    position: relative;
    width: 157px;
    min-width: 157px;
    padding-left: 15px;
    margin-top: 20px;
    margin-right: 20px;
    background-color: ${props => props.theme.box.button.folder.backgroundColor};
    border: 1px solid ${props => props.theme.box.button.folder.borderColor};
    border-radius: ${props => props.theme.box.button.borderRadius}px;
`;

const FolderDropdown = styled(Flex.Container).attrs(() => ({
    $alignItems: "center",
}))`
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
    height: 100%;
    background-color: ${props => props.theme.box.button.folder.backgroundColor};
    box-shadow: 0 0 15px ${props => props.theme.box.button.folder.backgroundColor};

    svg {
        margin: auto;
    }

    &:hover {
        cursor: pointer;
    }
`;
