// import React from "react";
import styled from "styled-components";
import { showForSr } from "@theme/GlobalStyle";

export const Checkmark = styled.span`
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    width: 0;
    width: 14px;
    height: 14px;
    background-color: lightgray;
    border: 2px solid white;
    border-radius: 50%;

    &::after {
        content: "";
        display: none;
        position: absolute;
        top: -2px;
        left: -2px;
        width: 14px;
        height: 14px;
        background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iNyIgY3k9IjciIHI9IjYuNSIgZmlsbD0id2hpdGUiIHN0cm9rZT0id2hpdGUiLz4KPHBhdGggZD0iTTMuNSA3TDUuODMzMzMgOS41TDEwLjUgNC41IiBzdHJva2U9IiM0MTQxNDEiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==")
            no-repeat;
    }
`;

export const CheckboxLabel = styled.span`
    ${showForSr}
`;

export const Checkbox = styled.label`
    display: block;
    position: relative;
    width: 14px;
    height: 14px;
    // margin-right: 10px;
    cursor: pointer;
    user-select: none;

    input {
        position: absolute;
        height: 0;
        width: 0;
        opacity: 0;
        cursor: pointer;

        &:checked ~ ${Checkmark}::after {
            display: block;
        }
    }
`;
