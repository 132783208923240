import { ReduxAction } from "@redux/actions";
import { AuthActionType } from "@redux/actions/AuthActions";
import { Cookie } from "@utils/Cookie";

export interface AuthReducerState {
    authToken: string | null;
}

export class AuthReducer {
    private static readonly initialState: AuthReducerState = {
        authToken: Cookie.getAuthToken(),
    };

    public static instance(state: AuthReducerState = AuthReducer.initialState, action: ReduxAction): AuthReducerState {
        switch (action.type) {
            case AuthActionType.logout:
                return { ...AuthReducer.initialState };
            case AuthActionType.updateAuthToken:
                return { ...state, authToken: action.authToken };
            default:
                return state;
        }
    }
}
