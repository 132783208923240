import React, { Component } from "react";
import { Asset, SearchListOptionsInput } from "@api/graphql/types";
import { DirectoryModal } from "@components/Directory/DirectoryModal";
import { Intl } from "@i18n/Intl";
import styled from "styled-components";
import { Button } from "@components/Button";
import { Flex } from "@components/Flex";
import { Logo } from "@components/Logo";
import { Search } from "@components/Search";
import { ActionBar } from "@components/ActionBar";
import SvgIcnCloud from "@components/svg/IcnCloud";
import SvgIcnFolderAdd from "@components/svg/IcnFolderAdd";
import { Color } from "@theme/Theme";
import SvgIcnClear from "./svg/IcnClear";
import { ApplicationState } from "@redux/reducers";
import { isEmbeddedView } from "@redux/selectors/AppStateSelectors";
import { connect, DispatchProp } from "react-redux";
import { OutgoingEvent, sendPMEvent } from "@utils/EmbeddedUtils";
import BreadcrumbBar from "./BreadcrumbBar";
import { UploadModal } from "@components/Upload/UploadModal";
import { AppStateActions } from "@redux/actions/AppStateActions";

type ReduxProps = {
    isEmbeddedView: boolean;
    maxSelectableAsset: number;
    currentDirectory: string | null;
    selectedAssets: Asset[];
    assetListOptions: SearchListOptionsInput;
};

type Props = {
    initialPath: string;
} & DispatchProp &
    ReduxProps;

interface State {
    createDirectoryModal: {
        show: boolean;
    };
    showFileUploadModal: boolean;
}

class AppBarComponent extends Component<Props, State> {
    public state: State = {
        createDirectoryModal: {
            show: false,
        },
        showFileUploadModal: false,
    };

    private readonly toggleCreateDirectoryModal = (): void => {
        this.setState({
            createDirectoryModal: {
                show: !this.state.createDirectoryModal.show,
            },
        });
    };

    private readonly toggleFileUploadModal = (): void => {
        this.setState({
            showFileUploadModal: !this.state.showFileUploadModal,
        });
    };

    private readonly onSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        this.props.dispatch(AppStateActions.setAssetSearch(e.currentTarget.value));
    };

    public render(): React.ReactElement {
        return (
            <StyledAppBar>
                <LogoSection>
                    <Logo ariaLabel="Logo" />
                </LogoSection>

                {this.props.isEmbeddedView && (
                    <EmbedCloseSection>
                        <Button.Action
                            btnLabel="Close"
                            onClick={() => {
                                sendPMEvent(OutgoingEvent.Close);
                            }}
                            icon={<SvgIcnClear width={20} height={20} color={Color.white} viewBox="0 0 14 14" />}
                        />
                    </EmbedCloseSection>
                )}

                <HeaderSection>
                    <BreadcrumbBar
                        initialPath={this.props.initialPath}
                        currentPath={this.props.currentDirectory || ""}
                    />

                    <Button.Action
                        btnLabel="Add folder"
                        icon={<SvgIcnFolderAdd />}
                        onClick={() => this.toggleCreateDirectoryModal()}
                    />

                    {this.props.selectedAssets.length > 0 && <ActionBar {...this.props} />}

                    <Search
                        label="Search"
                        placeholder={`${Intl.formatMessage({ id: "components.assets.searchInLibrary" })}...`}
                        onChange={this.onSearchChange}
                        value={this.props.assetListOptions.control?.search || ""}
                    />

                    <Button.Secondary
                        btnLabel={Intl.formatMessage({ id: "common.upload" })}
                        icon={<SvgIcnCloud />}
                        iconPosition="left"
                        onClick={this.toggleFileUploadModal}
                    />
                </HeaderSection>
                {this.state.createDirectoryModal.show && (
                    <DirectoryModal
                        mounted={this.state.createDirectoryModal.show}
                        onModalClose={this.toggleCreateDirectoryModal}
                        refreshDirectories={() => {
                            window.dispatchEvent(new CustomEvent("refreshDirectories"));
                            window.dispatchEvent(new CustomEvent("refreshSidebarDirectories"));
                        }}
                        currentDirectory={this.props.currentDirectory || undefined}
                    />
                )}
                <UploadModal mounted={this.state.showFileUploadModal} onToggleModal={this.toggleFileUploadModal} />
            </StyledAppBar>
        );
    }
}

const EmbedCloseSection = styled.div`
    position: absolute;
    right: 14px;
    top: 7px;
    color: ${Color.white};
`;

const StyledAppBar = styled.header`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    box-shadow: ${props => props.theme.appBar.boxShadow};
    z-index: 1000;
`;

const LogoSection = styled(Flex.Container).attrs(() => ({
    $alignItems: "center",
}))`
    padding: 0 20px;
    height: 50px;
    color: ${props => props.theme.appBar.logoSection.color};
    background-color: ${props => props.theme.appBar.logoSection.backgroundColor};
`;

const HeaderSection = styled(Flex.Container).attrs(() => ({
    $alignItems: "center",
}))`
    padding: 0 20px;
    height: 40px;
    background-color: ${props => props.theme.appBar.headerSection.backgroundColor};
`;

const mapStateToProps = (state: ApplicationState): ReduxProps => {
    return {
        isEmbeddedView: isEmbeddedView(state),
        maxSelectableAsset: state.appState.appConfiguration.maximumSelectableAsset || 0,
        currentDirectory: state.appState.currentDirectory,
        selectedAssets: state.appState.selectedAssets,
        assetListOptions: state.appState.assetOptions,
    };
};

const AppBar = connect(mapStateToProps)(AppBarComponent);

export { AppBar };
