import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import LogoImage from "./svg/Logo";

type LogoProps = {
    ariaLabel: string;
};

export const Logo = ({ ariaLabel }: LogoProps) => {
    return (
        <LogoLink to="/" aria-label={ariaLabel}>
            <LogoImage />
        </LogoLink>
    );
};

const LogoLink = styled(Link)`
    color: inherit;
    background-color: transparent;
`;
