import * as React from "react";

function SvgIcnDots(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width={2} height={10} fill="none" xmlns="http://www.w3.org/2000/svg" focusable={false} {...props}>
            <circle cx={1} cy={1} r={1} fill="#657ED6" />
            <circle cx={1} cy={5} r={1} fill="#657ED6" />
            <circle cx={1} cy={9} r={1} fill="#657ED6" />
        </svg>
    );
}

export default SvgIcnDots;
