import { Alert } from "@components/Alert/Alert";
import { AssetDeleteModal } from "@components/Assets/AssetDeleteModal";
import { AssetRenameModal } from "@components/Assets/AssetRenameModal";
import { Intl } from "@i18n/Intl";
import { ApplicationState } from "@redux/reducers";
import React from "react";
import { MapStateToProps, connect, DispatchProp } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import styled from "styled-components";
import { Asset, SearchListOptionsInput } from "@api/graphql/types";
import { Flex } from "@components/Flex";
import { FoldableSymbol } from "@components/Foldable/Foldable";
import { StyledFoldable, StyledFoldableToggle } from "@components/Foldable/StyledFoldable";
import { Image, LoadingImage } from "@components/Image/Image";
import { ImageRow, LoadingImageRow } from "@components/Image/ImageRow";
import SvgIcnArrowRight from "@components/svg/IcnArrowRight";
import clipboardCopy from "clipboard-copy/index";
import { Api } from "@api/Api";
import { debounce, isEqual } from "lodash";
import { AppStateActions } from "@redux/actions/AppStateActions";
import { AppConfigurationType } from "@redux/reducers/AppStateReducer";

type ReduxProps = {
    config: AppConfigurationType;
    assetListDisplay: boolean;
    currentDirectory: string | null;
    selectedAssets: Asset[];
    options: SearchListOptionsInput;
};

type Props = ReduxProps & DispatchProp & RouteComponentProps;

interface State {
    isLoading: boolean;
    count: number;
    assets: Asset[];
    renameModal: {
        show: boolean;
        selectedAsset?: Asset;
    };
    deleteModal: {
        show: boolean;
        selectedAsset?: Asset;
    };
}

class AssetsComponent extends React.Component<Props, State> {
    public state: State = {
        isLoading: true,
        count: 0,
        assets: [],
        renameModal: {
            show: false,
        },
        deleteModal: {
            show: false,
        },
    };

    componentDidMount() {
        this.fetchAssets();
        window.addEventListener("refreshAssets", () => this.fetchAssets());
        window.addEventListener("selectAllAssets", () => this.selectAllAssets());
    }

    componentDidUpdate(prevProps: Readonly<Props>) {
        if (!isEqual(prevProps.options, this.props.options)) {
            if (prevProps.options.control?.search !== this.props.options.control?.search) {
                this.submitSearch();
                return;
            }
            this.fetchAssets(prevProps.options.control?.offset === this.props.options.control?.offset);
        }
        if (prevProps.currentDirectory !== this.props.currentDirectory) {
            this.fetchAssets(true);
        }
    }

    componentWillUnmount() {
        window.removeEventListener("refreshAssets", () => this.fetchAssets());
        window.removeEventListener("selectAllAssets", () => this.selectAllAssets());
    }

    private readonly fetchAssets = (isRefresh: boolean = true): void => {
        if (!isRefresh && this.state.count === this.state.assets.length) {
            return;
        }
        this.setState({ isLoading: true }, async () => {
            try {
                const response = await Api.getAssets(this.props.currentDirectory, this.props.options);
                console.log({ response, isRefresh });
                this.setState(
                    {
                        isLoading: false,
                        count: response.count,
                        assets: isRefresh ? response.result : this.state.assets.concat(response.result),
                    },
                    () => console.log({ state: this.state })
                );
            } catch (error) {
                Alert.error({ title: Intl.getMessageFromError(error) });
                this.setState({ isLoading: false });
            }
        });
    };

    private readonly selectAllAssets = (): void => {
        if ((this.props.config.maximumSelectableAsset || 0) > 0) {
            return;
        }
        this.props.dispatch(AppStateActions.setSelectedAssets(this.state.assets));
    };

    private readonly submitSearch = debounce(() => {
        this.fetchAssets();
    }, 500);

    private readonly isAssetSelected = (asset: Asset): boolean => {
        return this.props.selectedAssets.some((selectedAsset: Asset) => selectedAsset.id === asset.id);
    };

    private readonly onUrlCopy = (url: string): void => {
        clipboardCopy(url).catch(() => {});
        Alert.success({ title: Intl.formatMessage({ id: "components.assets.onCopySuccess" }) });
    };

    private readonly renderLoadingImages = (imageCount: number = 4): React.ReactElement[] => {
        return new Array(imageCount).fill("1").map((_value, key) => {
            return <LoadingImage key={key} />;
        });
    };

    private readonly renderImages = (assets: Asset[]): React.ReactElement[] => {
        return assets.map(
            (asset: Asset, key: number): React.ReactElement => {
                return (
                    <Image
                        key={key}
                        asset={asset}
                        selected={this.isAssetSelected(asset)}
                        onSelect={() => this.props.dispatch(AppStateActions.toggleSelectedAsset(asset))}
                        onUrlCopy={this.onUrlCopy}
                        onRename={this.toggleRenameModal}
                        onDelete={this.toggleDeleteModal}
                    />
                );
            }
        );
    };

    private readonly renderLoadingImageRows = (imageRowCount: number = 4): React.ReactElement[] => {
        return new Array(imageRowCount).fill("1").map((_value, key) => {
            return <LoadingImageRow key={key} />;
        });
    };

    private readonly renderImageRows = (assets: Asset[]): React.ReactElement[] => {
        return assets.map(
            (asset: Asset, key: number): React.ReactElement => {
                return (
                    <ImageRow
                        key={key}
                        asset={asset}
                        selected={this.isAssetSelected(asset)}
                        onSelect={() => this.props.dispatch(AppStateActions.toggleSelectedAsset(asset))}
                        onUrlCopy={this.onUrlCopy}
                        onRename={this.toggleRenameModal}
                        onDelete={this.toggleDeleteModal}
                    />
                );
            }
        );
    };

    private readonly toggleRenameModal = (asset?: Asset): void => {
        this.setState({
            renameModal: {
                show: !this.state.renameModal.show,
                selectedAsset: asset,
            },
        });
    };

    private readonly toggleDeleteModal = (asset?: Asset): void => {
        this.setState({
            deleteModal: {
                show: !this.state.deleteModal.show,
                selectedAsset: asset,
            },
        });
    };

    public render(): React.ReactElement {
        const { renameModal, deleteModal, assets, isLoading } = this.state;

        return (
            <>
                <StyledFoldable
                    id="assets-foldable-1"
                    $shrinkToggleElement={true}
                    toggleElement={
                        <StyledFoldableToggle>
                            {Intl.formatMessage({ id: "components.assets.title" })}
                        </StyledFoldableToggle>
                    }
                    isOpenedDefault={true}
                    toggleIcon={{
                        element: (
                            <FoldableSymbol>
                                <SvgIcnArrowRight />
                            </FoldableSymbol>
                        ),
                        elementPositionH: "left",
                        $activeStyle: { transform: "rotate(90deg)" },
                    }}
                >
                    {this.props.assetListDisplay ? (
                        <StyledAssetsTable>
                            <thead>
                                <StyledAssetsTableRow>
                                    <StyledAssetstableCell>
                                        {Intl.formatMessage({ id: "components.assets.listTable.columns.options" })}
                                    </StyledAssetstableCell>
                                    <StyledAssetstableImages>
                                        {Intl.formatMessage({ id: "components.assets.listTable.columns.image" })}
                                    </StyledAssetstableImages>
                                    <StyledAssetstableTitle>
                                        {Intl.formatMessage({ id: "components.assets.listTable.columns.name" })}
                                    </StyledAssetstableTitle>
                                    <StyledAssetstableCell>
                                        {Intl.formatMessage({ id: "components.assets.listTable.columns.type" })}
                                    </StyledAssetstableCell>
                                    <StyledAssetstableCell>
                                        {Intl.formatMessage({ id: "components.assets.listTable.columns.fileSize" })}
                                    </StyledAssetstableCell>
                                    <StyledAssetstableCell>
                                        {Intl.formatMessage({ id: "components.assets.listTable.columns.imageSize" })}
                                    </StyledAssetstableCell>
                                </StyledAssetsTableRow>
                            </thead>
                            <tbody>
                                {assets.length && this.renderImageRows(assets)}
                                {isLoading && this.renderLoadingImageRows()}
                            </tbody>
                        </StyledAssetsTable>
                    ) : (
                        <StyledAssets>
                            {assets.length > 0 && this.renderImages(assets)}
                            {isLoading && this.renderLoadingImages()}
                        </StyledAssets>
                    )}
                </StyledFoldable>
                {renameModal.show && renameModal.selectedAsset && (
                    <AssetRenameModal
                        mounted={renameModal.show}
                        onModalClose={() => this.toggleRenameModal()}
                        asset={renameModal.selectedAsset}
                        refreshAssets={this.fetchAssets}
                    />
                )}
                {deleteModal.show && deleteModal.selectedAsset && (
                    <AssetDeleteModal
                        mounted={deleteModal.show}
                        onModalClose={() => this.toggleDeleteModal()}
                        asset={deleteModal.selectedAsset}
                        refreshAssets={this.fetchAssets}
                    />
                )}
            </>
        );
    }
}

const StyledAssets = styled(Flex.Container).attrs(() => ({
    $flexWrap: "wrap",
}))`
    @supports (display: grid) {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
        grid-gap: 20px;
    }
`;

const StyledAssetsTable = styled.table`
    table-layout: fixed;
    width: 100%;
    min-width: 630px;
    text-align: left;
    border-collapse: collapse;
    font-size: 12px;
`;

const StyledAssetsTableRow = styled.tr<{ $isSelected?: boolean }>`
    border-bottom: 1px solid #d9e1ff;

    td,
    th {
        padding: 10px 0 10px 20px;
        white-space: nowrap;
    }

    th {
        text-transform: uppercase;
    }

    ${props =>
        props.$isSelected
            ? `
      background-color: white;
      border-color: #cecece;
    `
            : ""}
`;

const StyledAssetstableImages = styled.th`
    width: 40px;
`;

const StyledAssetstableTitle = styled.th`
    width: 100%;
`;

const StyledAssetstableCell = styled.th`
    width: 120px;
`;

const mapStateToProps: MapStateToProps<ReduxProps, {}, ApplicationState> = (state: ApplicationState): ReduxProps => {
    return {
        config: state.appState.appConfiguration,
        assetListDisplay: state.appState.assetListDisplay,
        currentDirectory: state.appState.currentDirectory,
        selectedAssets: state.appState.selectedAssets,
        options: state.appState.assetOptions,
    };
};

const Assets = withRouter(connect(mapStateToProps)(AssetsComponent));

export { Assets };
