import React, { ReactNode } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";

// https://github.com/davidtheclark/react-aria-modal
import AriaModal from "react-aria-modal";
import { Color } from "@theme/Theme";
import { keyframesFadeIn } from "@theme/GlobalStyle";
import { Button } from "@components/Button";

interface State {
    left: number;
    modalActive: boolean;
    top: number;
}

interface Props {
    activateElement?: JSX.Element;
    dummyElement?: ReactNode;
    hasFullWidthChildren?: boolean;
    mounted?: boolean;
    onModalClose?: () => void;
    titleText: string;
    forceAlignment?: boolean;
    containerPositionFixed?: boolean;
    preventDefaultClick?: boolean;

    /**
     * Alignment of the modal window against the activateElement
     *
     * @type {ModalAlignment}
     * @memberof Props
     */
    modalAlignment?: ModalAlignment;

    /**
     * Should overlay the source element or place above/below
     *
     * @type {boolean}
     * @memberof Props
     */
    overlayInputElement?: boolean;

    /**
     * Use the activeElements offsetParent for calculations instead of own
     * Useful when the active element is absolute positioned, in that case, there is no
     * offsetTop and offsetLeft value.
     *
     * @type {boolean}
     * @memberof Props
     */
    useOffsetParentForCalculation?: boolean;

    /**
     * TBD, can be used to add extra offset
     *
     * @type {{ x: number, y: number }}
     * @memberof Props
     */
    useOffset?: { x: number; y: number };
    zIndex?: number;
}

export enum ModalAlignment {
    TopLeft,
    TopCenter,
    TopRight,
    BottomLeft,
    BottomCenter,
    BottomRight,
}

export default class ModalAbsolute extends React.Component<Props, State> {
    static defaultProps = {
        modalAlignment: ModalAlignment.BottomLeft,
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    activateElementRef: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    childContainerRef: any;

    constructor(props: Props) {
        super(props);

        this.state = {
            left: 0,
            modalActive: false,
            top: 0,
        };

        this.activateModal = this.activateModal.bind(this);
        this.deactivateModal = this.deactivateModal.bind(this);
        this.getApplicationNode = this.getApplicationNode.bind(this);
    }

    activateModal = () => {
        this.setState({ modalActive: true }, () => {
            this.setModalPosition();
        });
    };

    setModalPosition = () => {
        if (this.activateElementRef) {
            // eslint-disable-next-line react/no-find-dom-node
            const domNode = ReactDOM.findDOMNode(this.activateElementRef);
            if (domNode) {
                let calcNode = domNode;
                if (this.props.useOffsetParentForCalculation === true) {
                    const parent = (domNode as HTMLElement).offsetParent;
                    if (parent) {
                        calcNode = parent;
                    }
                }
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const { top, left } = this.calculatePositionBasedOnProp(calcNode as HTMLElement);
                this.setState({
                    left,
                    top,
                });
            }
        }
    };

    calculatePositionBasedOnProp = (domNode: HTMLElement) => {
        const rect = domNode.getBoundingClientRect();
        const top = rect.top + window.scrollY;
        let bottom = rect.bottom;
        const left = !this.props.hasFullWidthChildren ? rect.left : 0;
        const height = rect.height;
        const width = rect.width;

        let contentWidth = 0;
        let contentHeight = 0;
        if (this.childContainerRef) {
            // eslint-disable-next-line react/no-find-dom-node
            const childNode = ReactDOM.findDOMNode(this.childContainerRef);
            const scrollHeight = Math.max(
                document.body.scrollHeight,
                document.documentElement.scrollHeight,
                document.body.offsetHeight,
                document.documentElement.offsetHeight,
                document.body.clientHeight,
                document.documentElement.clientHeight
            );
            contentWidth = (childNode as HTMLElement).clientWidth;
            contentHeight = (childNode as HTMLElement).clientHeight;
            bottom = Math.max(scrollHeight, window.innerHeight) - top - height;
        }

        // console.log(
        //     top,
        //     right,
        //     bottom,
        //     left,
        //     width,
        //     height,
        //     contentWidth,
        //     contentHeight,
        //     document.documentElement.scrollHeight,
        //     rect
        // );

        switch (this.props.modalAlignment) {
            case ModalAlignment.TopLeft: {
                return {
                    top:
                        top >= contentHeight || this.props.forceAlignment === true
                            ? top - contentHeight + (this.props.overlayInputElement === true ? height : 0)
                            : top + height - (this.props.overlayInputElement === true ? height : 0),
                    left,
                };
            }
            case ModalAlignment.TopCenter: {
                return {
                    top:
                        top >= contentHeight || this.props.forceAlignment === true
                            ? top - contentHeight + (this.props.overlayInputElement === true ? height : 0)
                            : top + height - (this.props.overlayInputElement === true ? height : 0),
                    left: left + width / 2 - contentWidth / 2,
                };
            }
            case ModalAlignment.TopRight: {
                return {
                    top:
                        top >= contentHeight || this.props.forceAlignment === true
                            ? top - contentHeight + (this.props.overlayInputElement === true ? height : 0)
                            : top + height - (this.props.overlayInputElement === true ? height : 0),
                    left: left + width - contentWidth,
                };
            }
            case ModalAlignment.BottomLeft: {
                return {
                    top:
                        bottom >= contentHeight || this.props.forceAlignment === true
                            ? top + height - (this.props.overlayInputElement === true ? height : 0)
                            : top - contentHeight + (this.props.overlayInputElement === true ? height : 0),
                    left,
                };
            }
            case ModalAlignment.BottomCenter: {
                return {
                    top:
                        bottom >= contentHeight || this.props.forceAlignment === true
                            ? top + height - (this.props.overlayInputElement === true ? height : 0)
                            : top - contentHeight + (this.props.overlayInputElement === true ? height : 0),
                    left: left + width / 2 - contentWidth / 2,
                };
            }
            case ModalAlignment.BottomRight: {
                return {
                    top:
                        bottom >= contentHeight || this.props.forceAlignment === true
                            ? top + height - (this.props.overlayInputElement === true ? height : 0)
                            : top - contentHeight + (this.props.overlayInputElement === true ? height : 0),
                    left: left + width - contentWidth,
                };
            }
        }
    };

    deactivateModal = () => {
        this.setState({ modalActive: false }, () => {
            if (this.props.onModalClose) {
                this.props.onModalClose();
            }
        });
    };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    getApplicationNode = (): HTMLElement => document.getElementById("root");

    componentDidMount() {
        window.addEventListener("resize", this.setModalPosition);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.setModalPosition);
    }

    render(): JSX.Element {
        const { children, mounted, activateElement, dummyElement, titleText, ...otherProps } = this.props;
        const { modalActive } = this.state;
        const ActivateElement =
            activateElement &&
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            React.cloneElement(activateElement as React.ReactElement<any>, {
                onClick: (e: React.MouseEvent) => {
                    if (this.props.preventDefaultClick === true) {
                        e.stopPropagation();
                        e.preventDefault();
                    }
                    activateElement.props.onClick && activateElement.props.onClick();
                    this.activateModal();
                },
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                ref: (ref: any) => {
                    this.activateElementRef = ref;
                },
            });

        return (
            <>
                {dummyElement && modalActive ? (
                    <ActivateElementRelativeWrapper>
                        {ActivateElement}
                        <DummyElementWrapper aria-hidden="true">{dummyElement}</DummyElementWrapper>
                    </ActivateElementRelativeWrapper>
                ) : (
                    ActivateElement
                )}

                {(modalActive || mounted) && (
                    <AriaModal
                        focusDialog={false}
                        getApplicationNode={this.getApplicationNode}
                        includeDefaultStyles={false}
                        mounted={mounted}
                        onExit={this.deactivateModal}
                        scrollDisabled={this.props.containerPositionFixed ? true : false}
                        titleText={titleText}
                        verticallyCenter={false}
                    >
                        <StyledDeactivateUnderlay onClick={this.deactivateModal} />

                        <StyledModalContainer
                            ref={el => (this.childContainerRef = el)}
                            tabIndex={0}
                            style={{
                                left: this.state.left,
                                top: this.state.top,
                                right: !this.props.hasFullWidthChildren ? "" : 0,
                                position: this.props.containerPositionFixed ? "fixed" : "absolute",
                            }}
                            {...otherProps}
                        >
                            {children}
                        </StyledModalContainer>
                    </AriaModal>
                )}
            </>
        );
    }
}

const StyledDeactivateUnderlay = styled.div`
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1050;
`;

const StyledModalContainer = styled.div<{ zIndex?: number }>`
    animation: ${keyframesFadeIn} 300ms 1;
    position: absolute;
    z-index: ${props => props.zIndex || 1050};
    pointer-events: none;

    > * {
        pointer-events: all;
    }
`;

const ActivateElementRelativeWrapper = styled.div`
    position: relative;
`;

const DummyElementWrapper = styled.div`
    bottom: 0;
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
`;

const MenuOptions = styled.div`
    margin-top: 5px;
    background-color: ${Color.white};
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
    border-radius: 3px;

    &::before {
        top: 0;
        right: 14px;
        border: solid transparent;
        content: "";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(136, 183, 213, 0);
        border-bottom-color: ${Color.white};
        border-width: 0 6px 6px 6px;
    }
`;

const StyledMenuOption = styled(Button.Action).attrs(() => ({ $expanded: true }))`
    display: block;
    border-radius: 0;

    ${Button.LabelWrapper} {
        text-transform: none;
    }
`;

export const Menu = {
    Option: StyledMenuOption,
    Options: MenuOptions,
};
