import { ApiError, ApiErrorCode } from "@api/ApiError";
import { Mutations } from "@api/graphql/queries/Mutations";
import { Queries } from "@api/graphql/queries/Queries";
import { GraphQLClient, OnProgress } from "./graphql/GraphQLClient";
import * as GqlTypes from "./graphql/types";

class Api {
    public static async uploadAsset(
        title: string,
        file: File,
        directoryId: string | null,
        onProgress: OnProgress
    ): Promise<void> {
        await GraphQLClient.upload<GqlTypes.uploadAsset, GqlTypes.uploadAssetVariables>({
            mutation: Mutations.uploadAsset,
            variables: { title, file, directoryId },
            onProgress,
            file,
        });
    }

    public static async updateAsset(id: string, title: string): Promise<GqlTypes.Asset> {
        const response = await GraphQLClient.mutate<GqlTypes.updateAsset, GqlTypes.updateAssetVariables>({
            mutation: Mutations.updateAsset,
            variables: {
                id,
                title,
            },
        });

        return response.updateAsset;
    }

    public static async deleteAsset(id: string): Promise<void> {
        await GraphQLClient.mutate<GqlTypes.deleteAsset, GqlTypes.deleteAssetVariables>({
            mutation: Mutations.deleteAsset,
            variables: {
                id,
            },
        });
    }

    public static async deleteAssets(ids: string[]): Promise<void> {
        await GraphQLClient.mutate<GqlTypes.deleteAssets, GqlTypes.deleteAssetsVariables>({
            mutation: Mutations.deleteAssets,
            variables: {
                ids,
            },
        });
    }

    public static async getAssetDirectoriesByParentId(
        parent: string | null = null
    ): Promise<GqlTypes.AssetDirectory[]> {
        const response = await GraphQLClient.query<
            GqlTypes.getAssetDirectoriesByParentId,
            GqlTypes.getAssetDirectoriesByParentIdVariables
        >({
            query: Queries.getAssetDirectoriesByParentId,
            variables: {
                id: parent,
            },
        });

        if (response.getAssetDirectoriesByParentId.some(dir => dir === null)) {
            return [];
        }
        return response.getAssetDirectoriesByParentId as GqlTypes.AssetDirectory[];
    }

    public static async getAssetDirectoryById(id: string): Promise<GqlTypes.AssetDirectory> {
        const response = await GraphQLClient.query<
            GqlTypes.getAssetDirectoryById,
            GqlTypes.getAssetDirectoryByIdVariables
        >({
            query: Queries.getAssetDirectoryById,
            variables: {
                id,
            },
        });

        if (!response.getAssetDirectoryById) {
            throw new ApiError(ApiErrorCode.DIRECTORY_NOT_FOUND);
        }

        return response.getAssetDirectoryById;
    }

    public static async createAssetDirectory(
        variables: GqlTypes.createAssetDirectoryVariables
    ): Promise<GqlTypes.AssetDirectory | null> {
        const response = await GraphQLClient.mutate<
            GqlTypes.createAssetDirectory,
            GqlTypes.createAssetDirectoryVariables
        >({
            mutation: Mutations.createAssetDirectory,
            variables,
        });

        return response.createAssetDirectory;
    }

    public static async updateAssetDirectory(
        variables: GqlTypes.updateAssetDirectoryVariables
    ): Promise<GqlTypes.AssetDirectory | null> {
        const response = await GraphQLClient.mutate<
            GqlTypes.updateAssetDirectory,
            GqlTypes.updateAssetDirectoryVariables
        >({
            mutation: Mutations.updateAssetDirectory,
            variables,
        });

        return response.updateAssetDirectory;
    }

    public static async deleteAssetDirectory(id: string): Promise<void> {
        await GraphQLClient.mutate<GqlTypes.deleteAssetDirectory, GqlTypes.deleteAssetDirectoryVariables>({
            mutation: Mutations.deleteAssetDirectory,
            variables: {
                id,
            },
        });
    }

    public static async getAssets(
        assetDirectory: string | null,
        listOptions?: GqlTypes.SearchListOptionsInput
    ): Promise<GqlTypes.search_search> {
        const response = await GraphQLClient.query<GqlTypes.search, GqlTypes.searchVariables>({
            query: Queries.search,
            variables: {
                options: {
                    assetDirectory,
                    listOptions,
                },
            },
        });

        return response.search;
    }

    public static async moveAssetsToAssetDirectory(assetIds: string[], directoryId: string): Promise<void> {
        await GraphQLClient.mutate<GqlTypes.moveAssetsToAssetDirectory, GqlTypes.moveAssetsToAssetDirectoryVariables>({
            mutation: Mutations.moveAssetsToAssetDirectory,
            variables: {
                assetIds,
                directoryId,
            },
        });
    }
}

export { Api };
