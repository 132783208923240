import styled from "styled-components";
import { Foldable } from "./Foldable";

export const StyledFoldable = styled(Foldable)`
    padding: 20px;
`;

export const StyledFoldableToggle = styled.h2`
    margin-left: 10px;
    font-size: 12px;
    text-transform: uppercase;
`;
