export const localeHu = {
    common: {
        loading: "Betöltés...",
        save: "Mentés",
        close: "Bezárás",
        cancel: "Mégsem",
        upload: "Feltöltés",
        done: "Kész",
        unknown: "Ismeretlen",
        delete: "Törlés",
        move: "Áthelyezés",
    },
    components: {
        uploadModal: {
            title: "Fájl feltöltése",
            error: "Hiba",
            uploaded: "Feltölve",
        },
        dropzone: {
            placeholder: "Húzz ide egy fájlt, vagy kattints ide a fájl kiválasztásához",
        },
        directoryModal: {
            createTitle: "Mappa létrehozása",
            updateTitle: "Mappa módosítása",
            form: {
                name: "Mappa neve",
            },
            messages: {
                createSuccess: "A mappa sikeresen létrehozva!",
                updateSuccess: "A mappa sikeresen módosítva!",
            },
        },
        assets: {
            title: "Fájlok",
            listTable: {
                columns: {
                    options: "Opciók",
                    image: "Kép",
                    name: "Név",
                    type: "Típus",
                    fileSize: "Fájlméret",
                    imageSize: "Képméret",
                },
            },
            options: {
                copyUrl: "URL másolása",
                download: "Letöltés",
                rename: "Átnevezés",
                delete: "Törlés",
            },
            searchInLibrary: "Keresés a médiatárban",
            onCopySuccess: "Az URL sikeresen vágólapra másolva",
        },
        optionsBar: {
            options: {
                titleASC: "Név szerint növekvő",
                titleDESC: "Név szerint csökkenő",
                fileSizeASC: "Fájlméret szerint növekvő",
                fileSizeDESC: "Fájlméret szerint csökkenő",
                createdAtASC: "Létrehozás szerint növekvő",
                createdAtDESC: "Létrehozás szerint csökkenő",
                updatedAtASC: "Módosítás szerint növekvő",
                updatedAtDESC: "Módosítás szerint csökkenő",
            },
        },
        assetRenameModal: {
            title: "Fájl átnevezése",
            form: {
                title: "Név",
            },
            updateSuccess: "A fájl sikeresen átnevezve!",
        },
        assetDeleteModal: {
            title: "Fájl törlése",
            description: "Biztosan törölni szeretnéd a fájlt? Ez a művelet nem vonható vissza!",
            descriptionMultiple: "Biztosan törölni szeretnéd az alábbi fájlokat? Ez a művelet nem vonható vissza!",
            deleteSuccess: "A fájl sikeresen törölve!",
            deleteSuccessMultiple: "A fájlok sikeresen törölve lettek!",
        },
        assetMoveModal: {
            title: "Fálj(ok) áthelyezése",
            back: "Vissza az előző mappába",
            moveSuccess: "A fájl(ok) áthelyezése sikeres!",
        },
        directoryRenameModal: {
            title: "Mappa átnevezése",
            form: {
                title: "Név",
            },
            updateSuccess: "A mappa sikeresen átnevezve!",
        },
        directoryDeleteModal: {
            title: "mappa törlése",
            description:
                "Biztosan törölni szeretnéd a mappát? Ez a művelet nem vonható vissza és minden eleme törlésre kerül!",
            deleteSuccess: "A mappa sikeresen törölve!",
        },
        folders: {
            title: "Mappák",
            options: {
                rename: "Átnevezés",
                delete: "Törlés",
            },
        },
        actionBar: {
            selected: "{number} kiválasztva",
            delete: "Törlés",
            move: "Áthelyezés",
            download: "Letöltés",
            selectAll: "Összes kijelölése",
            clearAll: "Összes kijelölés törlése",
            insert: "Beillesztés",
        },
        loginPage: {
            title: "Bejelentkezés",
            form: {
                email: "E-mail cím",
                password: "Jelszó",
            },
            login: "Bejelentkezés",
            error: "A bejelentkezés hibás",
        },
    },
    error: {
        auth: {
            title: "Nem adtál meg azonosításhoz szükséges adatokat",
            description: "A médiatár nem használható, míg nem állítasz be azonosításhoz szükséges kulcsot.",
        },
        api: {
            INVALID_EMBED_CONFIG: "A megadott beágyazási konfiguráció hibás",
            UNKNOWN: "Ismeretlen hiba",
            INTERNAL_SERVER_ERROR: "Ismeretlen hiba",
            UNAUTHENTICATED: "Nincs bejelentkezve",
            NETWORK_ERROR: "Hálózati hiba",
            DIRECTORY_NOT_FOUND: "A mappa nem található",
        },
    },
};
