import { applyMiddleware, createStore, Middleware, Store, compose } from "redux";
import { createLogger } from "redux-logger";
import { ApplicationState, rootReducer } from "@redux/reducers";
import { enableBatching, batchDispatchMiddleware } from "redux-batched-actions";
import { Env, RunMode } from "@utils/Env";

const middlewares: Middleware[] = [batchDispatchMiddleware];

if (Env.runMode === RunMode.dev) {
    const logger: Middleware = createLogger();
    middlewares.push(logger);
}

const composeEnhancers: (<R>(a: R) => R) | undefined = Env.debugMode
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;
const enhancer = composeEnhancers ? composeEnhancers(applyMiddleware(...middlewares)) : undefined;

const store: Store<ApplicationState> = createStore(enableBatching(rootReducer), enhancer);

export { store };
